@use '@angular/material' as mat;
// Import Fuse core library
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
//@include mat.all-legacy-component-typographies();
//@include mat.legacy-core();
//$my-typography: mat.define-typography-config();
//@include mat.typography-hierarchy($my-typography);
//@include mat.core();
@include mat.all-component-typographies();
@include mat.core();
@import '../../@fuse/scss/core';
@import '../../app/app.theme';
// Select theme here:
@import "../../../themes/rieber/theme";

.icon-flipped {
  -o-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

// make spinners inside of buttons inline-block
button {
  >span {
    >mat-spinner {
      display: inline-block !important;
      margin-right: 10px;
    }
  }
}

mat-dialog-container {
  border-radius: 6px !important;
}

.page-layout.carded.fullwidth>.center .content-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

// workaround because of a bug in fuse
$foreground: map-get($theme, foreground);

.datatable-body {
  .datatable-row-wrapper {
    border-bottom-color: map-get($foreground, divider) !important;

    &:first-child {
      border-top-color: map-get($foreground, divider) !important;
    }
  }
}

::ng-deep ngx-material-timepicker {

  * div>span.active,
  .clock-face__clock-hand,
  * header {
    background-color: map-get($mat-branding, 500) !important;
  }

  .clock-face__clock-hand_minute:before {
    border-color: map-get($mat-branding, 500) !important;
  }

  * button.timepicker-button {
    color: map-get($mat-branding, 500) !important;
  }
}

#UD {

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    width: 800px !important;
  }
}

phoenix-datetimepicker div:first-of-type {
  display: flex;
  align-items: center;
}

phoenix-datetimepicker div:first-of-type button {
  --mdc-icon-button-state-layer-size: 32px !important;
  font-size: 32px;
  padding: 0 !important;
  margin: 0 0 0 1rem;
}

phoenix-datetimepicker div:first-of-type .mat-mdc-form-field {
  margin: 0 0 0 1rem;
  padding: 0;
}

phoenix-datetimepicker div:first-of-type .mat-mdc-form-field-infix {
  min-height: 24px;
  padding: 0.5rem 0 0.25rem 0;
  margin: 0 0 0 0.5rem;
}

phoenix-datetimepicker div:first-of-type .mat-mdc-form-field-subscript-wrapper {
  display: none;
}


phoenix-search-bar mat-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

phoenix-tasks-content-toolbar .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

phoenix-sensormanagement-content-toolbar .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

phoenix-dashboard-content-measurements mat-expansion-panel mat-expansion-panel-header span mat-panel-description div phoenix-button button mat-icon {
  font-size: 24px !important;
  margin: 0 !important;
}

phoenix-sensormanagement-content-toolbar {
  padding: 0 16px;
}

phoenix-sensormanagement-content-toolbar>div>mat-form-field .mdc-text-field {
  overflow: visible;
  width: 400px;
}

button {
  letter-spacing: 0 !important;
  font-size: 14px !important;
}

.mat-mdc-button>.mat-icon {
  padding: 0;
  margin-top: 0px !important;
  font-size: 24px !important;
}

.mat-mdc-card {
  border-radius: 0 !important;
}

/*
.mat-search {
  margin-top: 30px;
}
*/

.flip-icon {
  width: 48px !important;
  height: 48px !important;
  margin: 5px 5px 0 0 !important;
}

/*
.flip-icon {
  max-width: 50% !important;
  font-size: 24px !important;
  margin: 0 !important;
}*/

// .flip-icon {
//   --mdc-icon-button-state-layer-size: 24px !important;
// }
// .flip-icon mat-icon {
//   font-size: 24px !important;
//   margin: 0 !important;
// }
// .flip-icon .mat-mdc-button-touch-target {
//   width: 24px !important;
//   height: 24px !important;
// }

mat-toolbar .mat-body-1 {
  margin: 0;
}

mat-toolbar .change-tenant-button-wrapper {
  margin: 0 15px 0 20px;
}

phoenix-widget .head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 2px;
}

phoenix-widget .head .icon {
  flex-grow: 1;
  min-width: 0;
}

phoenix-widget .head div.spacer {
  width: 50px;
  height: 50px;
}

phoenix-widget .head button.flip-icon {
  margin: 2 2 0 0 !important;
  padding: 0 !important;
}

phoenix-widget .description {
  text-align: center;
  padding: 0 12px 8px 12px;
}

phoenix-widget .description .title {
  font-size: 2.0rem;
  font-weight: 300;
}

phoenix-widget .description .timestamp {
  font-size: 1.25rem;
  font-weight: normal;
}

phoenix-widget .widget-content-back {
  background-color: #eee !important;
}

phoenix-widget .mat-divider {
  width: 90% !important;
  margin-left: 5%;
}

phoenix-widget phoenix-widget-head mat-icon {
  -o-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  transition-duration: 0.5s;
}

phoenix-widget.flipped phoenix-widget-head mat-icon {
  -o-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
  transition-duration: 0.5s;
}

phoenix-detail-view-card .circle {
  border-radius: 100px;
}

phoenix-checklist-view-card .circle {
  border-radius: 100px;
}

.mat-mdc-snack-bar-container {
  &.green-snackbar {
    --mdc-snackbar-container-color: #4caf50;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.mat-mdc-snack-bar-container {
  &.red-snackbar {
    --mdc-snackbar-container-color: #f44336;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

/*
// this is the parent span angular inserts for icon inside mat-fab
.mat-button .mat-button-wrapper {
  vertical-align: middle;
}
*/

.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
}

.mat-datetimepicker-calendar-header {
  background-color: black !important;
  padding: 16px !important;
  font-size: 14px !important;
  color: #fff !important;
  box-sizing: border-box !important;
}

/*
// Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}
*/

//placeholder

/*
.mdc-floating-label {
  position: inherit !important;
  left: 0 !important;
  -webkit-transform-origin: left top !important;
  transform-origin: left top !important;
  line-height: 1.15rem !important;
  text-align: left !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  cursor: text !important;
  overflow: hidden !important;
  will-change: transform !important;
}

.mat-form-field-flex {
  display: inline-flex !important;
  align-items: baseline !important;
  box-sizing: border-box !important;
  width: 100% !important;
}
*/
.mdc-text-field--filled,
.mat-mdc-text-field-wrapper:hover,
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important
}

.mat-tooltip {
  font-size: 14px !important;
}


.mat-mdc-option {
  letter-spacing: 0 !important;
  margin: 0 !important;
}

.mdc-floating-label {
  letter-spacing: 0 !important;
}

.mat-mdc-select-min-line {
  letter-spacing: 0 !important;
}

.mdc-form-field {
  font-size: 16px !important;
}

/*
.mat-expansion-panel-header {
  padding: 0 !important;
}

.accordion {
  margin-left: 0px;
}
*/

.mat-content.mat-content-hide-toggle {
  margin: 0px !important;
}


.vertically-centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertically-centered-icon {
  margin-top: -12px;
}

.subtitle {
  font-weight: 300 !important;
  color: #383838;
}