@use '@angular/material' as mat;
$common-app-title: 'CHECK CLOUD';
$splashscreen-color: #ffffff;

$app-bg: '/assets/rieber/assets/CC-BG.webp';
$app-bg-card-header: '/assets/rieber/assets/CC-BG_card.webp';
$app-logo: '/assets/rieber/assets/logo.png';
$app-logo-header: '/assets/rieber/assets/logo.png';
$qrcode: '/assets/rieber/assets/qrcode.png';

// -----------------------------------------------------------------------------------------------------
// @ Define a rieber theme
// -----------------------------------------------------------------------------------------------------


/*$mat-white: (
  500: white,
  contrast: (
    500: $black-87-opacity
  )
);

$mat-black: (
  500: black,
  contrast: (
    500: white,
  )
);*/

$mat-branding-primary: (
  50 : #e7e7e7,
  100 : #c3c3c3,
  200 : #9c9c9c,
  300 : #747474,
  400 : #565656,
  500 : #383838,
  600 : #323232,
  700 : #2b2b2b,
  800 : #242424,
  900 : #171717,
  A100 : #ef7171,
  A200 : #ea4343,
  A400 : #fa0000,
  A700 : #e00000,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$mat-branding: (
  50 : #e7e7e7,
  100 : #c3c3c3,
  200 : #9c9c9c,
  300 : #747474,
  400 : #565656,
  500 : #383838,
  600 : #323232,
  700 : #2b2b2b,
  800 : #242424,
  900 : #171717,
  A100 : #ef7171,
  A200 : #ea4343,
  A400 : #fa0000,
  A700 : #e00000,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

//Palettes
$custom-primary: mat.define-palette($mat-branding-primary);
$custom-accent: mat.define-palette($mat-branding);
$custom-warn: mat.define-palette(mat.$red-palette);

// Create the Material theme object
// $custom-theme: mat.define-light-theme($custom-primary, $custom-accent, $custom-warn);
$custom-theme: mat.define-light-theme((color: (primary: $custom-primary,
        accent: $custom-accent,
        warn: $custom-warn ),
      typography: ( // brand-family: 'Comic Sans',
        // bold-weight: 900
      ),
      density: ( // scale: -1
      )));

body.theme-gc {
  // Generate the Angular Material theme
  //@include mat.all-legacy-component-themes($custom-theme);
  @include mat.all-component-themes($custom-theme);
  //$my-typography: mat.define-typography-config();
  //@include mat.typography-hierarchy($my-typography);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($custom-theme);

  // Apply the theme to the user components
  @include components-theme($custom-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $custom-primary,
    accent: $custom-accent,
    warn: $custom-warn
  );

@include fuse-color-classes($palettes);
}

/*$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

// Force the input field font sizes to 16px
$typography: mat-typography-config(
  $input: mat-typography-level(16px, 1.125, 400)
);*/

/*.nav {
  .nav-item {
    .nav-link {
      &.active {
        background-color: mat-color($accent, default-contrast) !important;
        color: mat-color($accent) !important;

        &, .nav-link-icon {
          color: mat-color($accent) !important;
        }
      }
    }
  }
}*/

.extenderColor {
  background-color: mat.get-color-from-palette($custom-accent, default-contrast) !important;
  color: mat.get-color-from-palette($custom-accent) !important;
}

.qrcode {
  background: url($qrcode);
  background-size: contain;
}


.fuse-transparent-bg {
  background-color: rgba(255, 255, 255, 0) !important;
}