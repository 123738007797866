@use '@angular/material' as mat;

html,
body {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {

  // Boxed
  &.boxed {
    max-width: 1200px;
    margin: 0 auto;

    @include mat.elevation(8);
  }
}